import React, { useState } from "react";
import Breadcrumb from "../layout/breadcrumb";
import {
  Container,
  Row,
  Col,
  Card,
  CardHeader,
  Table,
  CardBody,
  Input,
  FormGroup,
  Form,
  CardFooter,
  Modal,
  ModalHeader,
  ModalBody,
  Label,
  ModalFooter,
  Button,
} from "reactstrap";
import Layout from "./Layout";
import { useEffect } from "react";
import { getMethod, getMethodAuth, postMethod, postMethodFormData } from "../api";
import Loader from "../layout/loader/Loader";
import Pagination from "../utils/Pagination";
import { Link } from "react-router-dom";
import { useDispatch } from "react-redux";
import { useridHandler } from "../store/slices/getGameBettingWithid";
import UserBalance from "../utils/UserBalance";
import TableScroll from "../utils/TableScroll";
import { currentDate, decodeData, numericeValueFilter, relativeTime } from "../utils/Helper";
import { AGENT_API, LOGIN_IP_INFO_API, STREAMER_API, USER_API, USER_UPDATE_API } from "../api/routes";
import SweetAlert from "sweetalert2";
import ToogleSwitch from "../utils/Switch";
import { point } from "leaflet";

const User = () => {
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(true);
  const [totalPage, setTotalPage] = useState(0);
  const [from, setFrom] = useState(1);
  const [page, setPage] = useState(1);
  const [searchName, setSearchName] = useState("");
  const [searchId, setSearchId] = useState("");
  const [action, setAction] = useState("");
  const [status, setStatus] = useState("All");
  const [toPage, setToPage] = useState(0);
  const [totalUsers, setTotalUsers] = useState(0);
  const [refr, setRefr] = useState(true);
  const dispatch = useDispatch();
  const [showUserDetail, setShowUserDetail] = useState(false);
  const [userDetailInfo, setUserDetailInfo] = useState([]);
  const [editUserModal, setEditUserModal] = useState(false);
  const [userStatus, setUserStatus] = useState("");
  const [newPass, setNewPass] = useState("");
  const [newPassValidate, setNewPassValidate] = useState("");
  const [userEditId, setUserEditId] = useState("");
  const [authRole, setAuthRole] = useState("");
  const [streamers, setStreamers] = useState([]);
  const [searchStreamer, setSearchStreamer] = useState('');
  const [streamerEditId, setStreamerEditId] = useState('');
  const [role, setRole] = useState('user');
  const [agents, setAgents] = useState([]);
  const [agentId, setAgentId] = useState('');
  const [agentSearch, setAgentSearch] = useState('');
  const [percent, setPercent] = useState('');

  const userDetailHandler = () => {
    setShowUserDetail(!showUserDetail);
  };

  useEffect(() => {
    const token = decodeData(localStorage.getItem("y_g_n_d_a"));
    if (!token) return;
    if (token) setAuthRole(token?.user_role);
    (async () => {
      setLoading(true);
      const res = await getMethod(
        `${USER_API}?name=${searchName}&role=${role}&id=${searchId}&user_status=${status}&status=${action}&agent_id=${agentSearch}&page=${page}&limit=50&streamer_id=${searchStreamer}`,
        token.token
      );
      res && setLoading(false);
      if (res?.status === "success") {
        setData(res?.data?.map(el => {
          let temp_el = {...el, role: role || 'user'}
          return temp_el;
        }));
        if (Number(page) > 1) {
          res?.meta?.per_page && setFrom((res?.meta?.per_page *( Number(page) - 1)) + 1);
        } else {
          setFrom(1);
        }
        res?.meta?.last_page && setTotalPage(res?.meta?.last_page);
        res?.meta?.to && setToPage(res?.meta?.to);
        res?.meta?.total && setTotalUsers(res?.meta?.total);
      }
      if (token?.user_role === 'admin' || token?.user_role === 'staff' || token?.user_role === 'super') {
        const resStreamer = await getMethod(STREAMER_API, token.token);
        const resAgent = await getMethod(AGENT_API, token.token);
        resStreamer?.status === 'success' && setStreamers(resStreamer?.data);
        resAgent?.status === 'success' && setAgents(resAgent?.data);
      }
    })();
  }, [refr, page]);

  const editSubmitHandler = async () => {
    const token = decodeData(localStorage.getItem("y_g_n_d_a"));
    if (newPass && newPass.length !== 8) {
      setNewPassValidate("Password must be 8 characters.");
      return;
    }
    const statusToggle = {
      status: userStatus,
      streamer_id: `${streamerEditId}`,
      agent_id: `${agentId}`,
      password: newPass,
      percent: `${percent}`,
    };
    if (!token) return;
    setLoading(true);
    const res = await postMethod(
      `${USER_UPDATE_API(userEditId)}?updated_at=${currentDate()}`,
      statusToggle,
      token.token
    );
    res && setLoading(false);
    if (res?.status === "success") {
      setEditUserModal(false);
      setUserEditId("");
      setUserStatus("");
      setNewPass("");
      setRefr(!refr);
      SweetAlert.fire({
        title: "Updated!",
        text: "You has been updated.",
        icon: "success",
        width: 300,
      });
      return;
    } else {
      SweetAlert.fire({
        title: "Sorry!",
        text: res?.message ?? "Something wrong, Try again!",
        icon: "error",
        width: 300,
      });
      return;
    }
  };

  const searchSubmitHandler = () => {
    setRefr((prev) => !prev);
  };

  const searchHandler = (e) => {
    setSearchName(e.target.value);
  };
  const searchIdHandler = (e) => {
    setSearchId(e.target.value);
  };

  const statusHandler = (e) => {
    setStatus(e.target.value);
  };

  const actionHandler = (e) => {
    setAction(e.target.value);
  };

  const moreDetailHandler = async (user_id, last_login) => {
    setLoading(true);
    const token = decodeData(localStorage.getItem("y_g_n_d_a"));
    if (!token) return;
    setLoading(true);
    const res = await getMethodAuth(
      `${LOGIN_IP_INFO_API}?user_id=${user_id}`,
      token.token
    );
    res && setLoading(false);
    if (res?.status === "success") {
      setShowUserDetail(!showUserDetail);
      setUserDetailInfo({
        ...res?.data[res?.data?.length - 1], last_login_at: last_login
      });
    }
  };

  const popupToggler = async (edit_id, status, type) => {
    const formData = {
      [type]: status === "active" ? "disable" : "active",
    }
    const token = decodeData(localStorage.getItem("y_g_n_d_a"));
    if (!token) return;
    setLoading(true);
    const res = await postMethod(
      `${USER_UPDATE_API(edit_id)}?updated_at=${currentDate()}`,
      formData,
      token.token
    );
    res && setLoading(false);
    if (res?.status === "success") {
      setRefr(!refr);
      return;
    } else {
      SweetAlert.fire({
        icon: "error",
        width: 300,
        title: "Sorry!",
        text: res?.message ?? "Something wrong, Try again!",
      });
      return;
    }
  };

  const allowRoles = ['staff', 'super', 'admin'];
  const editableRoles = ['user', ''];

  return (
    <>
      <Layout>
        <Breadcrumb title="Users" />
        <Container fluid={true}>
          {loading && <Loader />}
          <Row>
            <Col sm="12">
              <Card>
                <CardHeader className="py-4">
                  <Col sm="12">
                    <Form>
                      <Row style={{ justifyContent: "flex-end" }}>
                        <Col md="4 px-1 mb-2">
                          <Input
                            className="form-control"
                            onChange={searchIdHandler}
                            type="number"
                            placeholder="Search ID"
                          />
                        </Col>
                        <Col md="4 px-1 mb-2">
                          <Input
                            className="form-control"
                            onChange={searchHandler}
                            type="text"
                            placeholder="Search Name"
                          />
                        </Col>
                        {
                            authRole === 'admin' &&
                            <Col md="4 px-1 mb-2">
                                <Input type="select" name="select" className="form-control" onChange={e => setSearchStreamer(e.target.value)}>
                                    <option value="">Select Streamer</option>
                                    <option value="">All</option>
                                    {
                                        streamers?.length > 0 &&
                                        streamers?.map((streamer, i) =>
                                            <option value={streamer.id} key={streamer.id}>{streamer.name}</option>
                                        )
                                    }
                                </Input>
                            </Col>
                        }
                        {
                            authRole === 'admin' &&
                            <Col md="4 px-1 mb-2">
                                <Input type="select" name="select" className="form-control" onChange={e => setAgentSearch(e.target.value)}>
                                    <option value="">Select Agent</option>
                                    <option value="">All</option>
                                    {
                                        agents?.length > 0 &&
                                        agents?.map((agent, i) =>
                                            <option value={agent.id} key={agent.id}>{agent.name}</option>
                                        )
                                    }
                                </Input>
                            </Col>
                        }
                        {
                            authRole === 'admin' &&
                            <Col md="4 px-1 mb-2">
                                <Input type="select" name="select" className="form-control" value={role} onChange={e => setRole(e.target.value)}>
                                    <option value="">Select Role</option>
                                    <option value="user">User</option>
                                    <option value="streamer">Streamer</option>
                                    <option value="staff">Staff</option>
                                    <option value="super">Super</option>
                                    <option value="admin">Admin</option>
                                </Input>
                            </Col>
                        }
                        {/* {
                          authRole === 'admin' && */}
                          <Col md="4 px-1 mb-2">
                            <Input
                              type="select"
                              name="select"
                              className="form-control"
                              onChange={statusHandler}
                            >
                              <option value="">Select Status</option>
                              <option value="Active">Active</option>
                              <option value="Inactive">Inactive</option>
                            </Input>
                          </Col>
                        {/* } */}
                        <Col md="4 px-1 mb-2">
                          <Input
                            type="select"
                            name="select"
                            className="form-control"
                            onChange={actionHandler}
                          >
                            <option value="">Select Action</option>
                            <option value="active">Unban</option>
                            <option value="disable">Ban</option>
                          </Input>
                        </Col>
                        <Col md="4 px-1 mb-2">
                          <button
                            className="w-100 btn text-white btn-primary"
                            disabled={loading}
                            type="button"
                            onClick={searchSubmitHandler}
                          >
                            {loading ? "Loading..." : "Submit"}
                          </button>
                        </Col>
                      </Row>
                    </Form>
                  </Col>
                </CardHeader>
                <CardBody className="position-relative">
                  <TableScroll>
                    <Table className="table-border-vertical">
                      <thead>
                        <tr className="bg-dark">
                          <th className="text-center text-white" scope="col">
                            <b>{"No."}</b>
                          </th>
                          <th className="text-center text-white" scope="col">
                            <b>{"ID"}</b>
                          </th>
                          <th className="text-center text-white" scope="col">
                            <b>{"Name"}</b>
                          </th>
                          {allowRoles.includes(authRole) && (
                            <th className="text-center text-white" scope="col">
                              <b>{"Reg Phone"}</b>
                            </th>
                          )}
                          <th className="text-center text-white" scope="col">
                            <b>{"Balance"}</b>
                          </th>
                          <th className="text-center text-white" scope="col">
                            <b>{"Status"}</b>
                          </th>
                          <th className="text-center text-white" scope="col">
                            <b>{"Streamer's"}</b>
                          </th>
                          <th className="text-center text-white" scope="col">
                            <b>{"Agent's"}</b>
                          </th>
                          <th className="text-center text-white" scope="col">
                            <b>{"Perent ( % )"}</b>
                          </th>
                          {allowRoles.includes(authRole) && (
                            <th className="text-center text-white" scope="col">
                              <b>{"Deposit Status"}</b>
                            </th>
                          )}
                          {allowRoles.includes(authRole) && (
                            <th className="text-center text-white" scope="col">
                              <b>{"Withdraw Status"}</b>
                            </th>
                          )}
                          {allowRoles.includes(authRole) && (
                            <th className="text-center text-white" scope="col">
                              <b>{"Action"}</b>
                            </th>
                          )}
                          <th className="text-center text-white" scope="col">
                            <b>{"Reg Date & Time"}</b>
                          </th>
                          {allowRoles.includes(authRole) && (
                            <th className="text-center text-white" scope="col">
                              <b>{"Last IP"}</b>
                            </th>
                          )}
                          {allowRoles.includes(authRole) && (
                            <th className="text-center text-white" scope="col">
                              <b>{"Last Login"}</b>
                            </th>
                          )}
                          {allowRoles.includes(authRole) && (
                            <th className="text-center text-white" scope="col">
                              <b>{"More Detail"}</b>
                            </th>
                          )}
                        </tr>
                      </thead>
                      <tbody>
                        {data.length > 0 &&
                          data.map((user, i) => (
                            <tr key={i}>
                              <th className="text-center" scope="row">{`${
                                i + from
                              }.`}</th>
                              <td className="text-center">{user.id}</td>
                              <td className="text-center">
                                {
                                  (editableRoles.includes(user?.role) && allowRoles.includes(authRole)) ? 
                                  <Link
                                    to={"/admin/games-betting/"}
                                    onClick={() =>
                                      dispatch(useridHandler(user.id))
                                    }
                                  >
                                    {user.name}
                                  </Link>
                                  :
                                  <span>{user.name}</span>
                                }
                              </td>
                              {allowRoles.includes(authRole) && (
                                <td className="text-center">{user.phone}</td>
                              )}
                              <td style={{ textAlign: "right", position: 'relative' }}>
                                {
                                  <UserBalance
                                    status={user.user_status}
                                    id={user.id}
                                    role={user?.role}
                                  />
                                }
                              </td>
                              <td className="text-center">
                                {user.user_status === "Active" ? (
                                  <i
                                    className="fa fa-circle font-success"
                                    style={{ fontSize: "10px" }}
                                  />
                                ) : (
                                  <i
                                    className="fa fa-circle font-danger"
                                    style={{ fontSize: "10px" }}
                                  />
                                )}
                              </td>
                              <td className="text-center">
                                { user?.streamer_name ?? '- -' }
                              </td>
                              <td className="text-center">
                                { user?.agent?.name ?? '- -' }
                              </td>
                              <td className="text-center">
                                { user?.percent ?? '0' } %
                              </td>
                              {
                                allowRoles.includes(authRole) && 
                              <td className="text-center p-0 pt-2">
                                  {
                                    editableRoles.includes(user?.role) ? <ToogleSwitch status={user?.deposit_status === 'active' ? true : false} event={() => popupToggler(user?.id, user?.deposit_status, 'deposit_status')} />
                                    : '- -'
                                  }
                              </td>
                              }
                              {
                                allowRoles.includes(authRole) &&
                              <td className="text-center p-0 pt-2">
                                  {
                                    editableRoles.includes(user?.role) ? <ToogleSwitch status={user?.withdraw_status === 'active' ? true : false} event={() => popupToggler(user?.id, user?.withdraw_status, 'withdraw_status')} />
                                    : '- -'
                                  }
                              </td>
                              }
                              {allowRoles.includes(authRole) && (
                                <td className="text-center">
                                <i
                                  onClick={() => {
                                    setEditUserModal(true);
                                    setUserStatus(user.status);
                                    setUserEditId(user.id);
                                    setStreamerEditId(user.streamer_id ?? '')
                                    setAgentId(user.agent_id ?? '')
                                    setPercent(user.percent ?? '')
                                  }}
                                  className={`fa fa-pencil ${
                                    user?.status === "active"
                                      ? "font-success"
                                      : "font-danger"
                                  }`}
                                  style={{
                                    width: 35,
                                    fontSize: 18,
                                    padding: 3,
                                    cursor: "pointer",
                                  }}
                                ></i>
                              </td>
                              )}
                              <td className="text-center">{user.created_at}</td>
                              {allowRoles.includes(authRole) && (
                                <td className="text-center fw-bold">
                                  {user.ip_address ?? "Unknown"}
                                </td>
                              )}
                              {allowRoles.includes(authRole) && (
                                <td className="text-center fw-bold">
                                  {user.last_login_at?.includes("years ago")
                                    ? "- -"
                                    : relativeTime(user.last_login_at)}
                                </td>
                              )}
                              {allowRoles.includes(authRole) && (
                                <td
                                  className="text-center text-primary text-decoration-underline fw-bold"
                                  onClick={() =>
                                    moreDetailHandler(
                                      user.id,
                                      user.last_login_at
                                    )
                                  }
                                >
                                  {"Click to View"}
                                </td>
                              )}
                            </tr>
                          ))}
                      </tbody>
                    </Table>
                  </TableScroll>
                </CardBody>
                <CardFooter className="d-flex justify-content-between align-items-center">
                  {data?.length > 0 && (
                    <div
                      style={{
                        fontSize: "16px",
                        letterSpacing: "0.6px",
                        wordSpacing: "3px",
                      }}
                    >
                      Showing {from} to {toPage} of{" "}
                      {totalUsers?.toLocaleString("en-us")} entries
                    </div>
                  )}

                  <Pagination
                    setPage={setPage}
                    totalPage={totalPage}
                    setLoading={setLoading}
                  />
                </CardFooter>
              </Card>
            </Col>
          </Row>
        </Container>
      </Layout>
      {
        <Modal isOpen={showUserDetail} toggle={userDetailHandler} centered>
          <Form>
            <ModalHeader toggle={userDetailHandler}>
              User Login Detail
            </ModalHeader>
            <ModalBody>
              <FormGroup>
                <Label htmlFor="">IP Address</Label>
                <Input
                  type="text"
                  disabled={true}
                  value={userDetailInfo?.ip_address ?? "Unknown IP Address"}
                  className="form-control bg-white"
                />
              </FormGroup>
              <FormGroup>
                <Label htmlFor="">Browser</Label>
                <Input
                  type="text"
                  disabled={true}
                  value={userDetailInfo?.browser_name ?? "Unknown Browser"}
                  className="form-control bg-white"
                />
              </FormGroup>
              <FormGroup>
                <Label htmlFor="">Device Name</Label>
                <Input
                  type="text"
                  disabled={true}
                  value={userDetailInfo?.device_name ?? "Unknown Device"}
                  className="form-control bg-white"
                />
              </FormGroup>
              <FormGroup>
                <Label htmlFor="">Last Login</Label>
                <Input
                  type="text"
                  disabled={true}
                  value={userDetailInfo?.last_login_at ?? "00:00:00"}
                  className="form-control bg-white"
                />
              </FormGroup>
            </ModalBody>
            <ModalFooter>
              <Button color="secondary" onClick={userDetailHandler}>
                Close
              </Button>
            </ModalFooter>
          </Form>
        </Modal>
      }{" "}
      {
        <Modal
          isOpen={editUserModal}
          toggle={(e) => setEditUserModal(false)}
          centered
        >
          <Form
            onSubmit={(e) => {
              e.preventDefault();
              editSubmitHandler(e);
            }}
          >
            <ModalHeader toggle={(e) => setEditUserModal(false)}>
              Edit User
            </ModalHeader>
            <ModalBody>
              {
                allowRoles.includes(authRole) &&
                <FormGroup>
                  <Label htmlFor="">New Pasword</Label>
                  <Input
                    className="form-control"
                    defaultValue={newPass}
                    type="text"
                    placeholder="Enter New Password"
                    onChange={(e) => {
                      setNewPass(e.target.value);
                      setNewPassValidate("");
                    }}
                  />
                  {newPassValidate && (
                    <div className="invalid-feedback d-block">
                      {newPassValidate}
                    </div>
                  )}
                </FormGroup>
              }
              {
                allowRoles.includes(authRole) &&
                <FormGroup>
                  <Label htmlFor="">Percent ( % )</Label>
                  <Input
                    className="form-control"
                    value={percent}
                    type="text"
                    placeholder="Enter Percent ( % )"
                    onChange={(e) => {
                      const value = numericeValueFilter(e.target.value);
                      if (Number(value) <= 100) {
                        setPercent(value);
                      }
                    }}
                  />
                </FormGroup>
              }
              {
                allowRoles.includes(authRole) &&
              <Row>
                <Col className="mb-3">
                <Label>Select Agent</Label>
                    <Input type="select" name="select" className="form-control" value={agentId} onChange={e => setAgentId(e.target.value)}>
                        <option value="">Select Agent</option>
                        {
                          agents?.length > 0 &&
                          agents?.map((agent, i) =>
                              <option value={agent.id} key={agent.id}>{agent.name}</option>
                          )
                        }
                    </Input>
                  </Col>
              </Row>
              }
              {
                allowRoles.includes(authRole) &&
              <Row>
                <Col className="mb-3">
                <Label>Select Streamer</Label>
                    <Input type="select" name="select" className="form-control" value={streamerEditId} onChange={e => setStreamerEditId(e.target.value)}>
                        <option value="">Select Streamer</option>
                        {
                          streamers?.length > 0 &&
                          streamers?.map((streamer, i) =>
                              <option value={streamer.id} key={streamer.id}>{streamer.name}</option>
                          )
                        }
                    </Input>
                  </Col>
              </Row>
              }
              <Row>
                <Col>
                  <div className="mb-0">
                    <Label>User Status</Label>
                    <Input
                      type="select"
                      name="select"
                      className="form-control form-control-sm digits"
                      onChange={(e) => setUserStatus(e.target.value)}
                      value={userStatus}
                    >
                      <option value="">Choose</option>
                      <option value="active">Active</option>
                      <option value="disable">Disabled</option>
                    </Input>
                  </div>
                </Col>
              </Row>
            </ModalBody>
            <ModalFooter>
              <Button
                color="secondary"
                onClick={(e) => setEditUserModal(false)}
              >
                Close
              </Button>
              <Button color="primary" type="submit">
                Save
              </Button>
            </ModalFooter>
          </Form>
        </Modal>
      }
    </>
  );
};

export default User;
